/* Plugin imports */
import Alpine from 'alpinejs';
import { passwordStrength } from 'check-password-strength';

class PasswordStrength {
    constructor() {
        this.validClass = '_valid';

        document.addEventListener('alpine:init', () => {

            // Magic: $passwordStrength
            Alpine.magic('passwordStrength', el => {
                this._check(el);
            });

            // Directive: x-passwordStrength
            Alpine.directive('passwordStrength', (el) => {
                this._check(el);
            });

        });
    }

    _check(el) {
        const parentEl = el.parentElement.parentElement;
        const passwordResponse = passwordStrength(el.value);

        // password length is 8 or more characters
        if (passwordResponse.length >= 8) {
            parentEl.querySelector('.password-strength-limit').classList.add(this.validClass);
        }
        else {
            parentEl.querySelector('.password-strength-limit').classList.remove(this.validClass);
        }

        // Password contains lowercase
        if (passwordResponse.contains.includes('lowercase')) {
            parentEl.querySelector('.password-strength-lowercase').classList.add(this.validClass);
        }
        else {
            parentEl.querySelector('.password-strength-lowercase').classList.remove(this.validClass);
        }

        // Password contains uppercase
        // if (passwordResponse.contains.includes('uppercase')) {
        //     parentEl.querySelector('.password-strength-uppercase').classList.add(this.validClass);
        // }
        // else {
        //     parentEl.querySelector('.password-strength-uppercase').classList.remove(this.validClass);
        // }

        // Password contains numbers
        if (passwordResponse.contains.includes('number')) {
            parentEl.querySelector('.password-strength-number').classList.add(this.validClass);
        }
        else {
            parentEl.querySelector('.password-strength-number').classList.remove(this.validClass);
        }

        // Password contains symbols
        if (passwordResponse.contains.includes('symbol')) {
            parentEl.querySelector('.password-strength-special').classList.add(this.validClass);
        }
        else {
            parentEl.querySelector('.password-strength-special').classList.remove(this.validClass);
        }

    }
}

export default new PasswordStrength();
